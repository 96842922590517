import {Dropdown} from "react-bootstrap";
import SettingsIcon from "@mui/icons-material/Settings";
import React from "react";
import PopupOrderDetails from "./PopupOrderDetails";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PopupDeleteOrder from "./PopupDeleteOrder";
import DownloadExtract from "./DownloadExtract";
import {ApiUrl} from "../../../auth/authMethods";
import {useUser} from "../../../auth/AuthProvider";
import AddContratoModal from "../../../component/AddContratoModel";
import AddContrato from "./AddContrato";
import PopupEditRecipient from "./PopupEditRecipient";

export default function OrderActions({item, setLoadData}) {

    const baseUrl = `${ApiUrl}/pedidos`
    const {user} = useUser()

    const detailsLabel = <>
        <VisibilityIcon fontSize="medium"/>
        &nbsp; Detalhes do Lote
    </>

    return <Dropdown drop='down-centered'>
        <Dropdown.Toggle variant="secondary">
            Ações &nbsp;
            <SettingsIcon fontSize="medium"/>
        </Dropdown.Toggle>

        <Dropdown.Menu>
            <PopupOrderDetails
                item={item} setLoadData={setLoadData}
                label={detailsLabel} buttonClass={'dropdown-item'}/>
            {user.isAdmin && <>
                <AddContrato pedidoId={item.id} setLoadData={setLoadData}/>
                <PopupEditRecipient item={item} setLoadData={setLoadData} />
                <PopupDeleteOrder item={item} setLoadData={setLoadData} baseUrl={baseUrl}/>
            </>}
            <DownloadExtract id={item.id} lote={item.numero}/>
        </Dropdown.Menu>

    </Dropdown>
}